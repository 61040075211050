import { render, staticRenderFns } from "./uploaded-certificates.vue?vue&type=template&id=2e39b2fa&scoped=true&"
import script from "./uploaded-certificates.vue?vue&type=script&lang=js&"
export * from "./uploaded-certificates.vue?vue&type=script&lang=js&"
import style0 from "./uploaded-certificates.vue?vue&type=style&index=0&id=2e39b2fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e39b2fa",
  null
  
)

export default component.exports