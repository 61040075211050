<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <!-- :loading="loading" -->
        <!-- v-model="selected" -->
        <v-data-table
          :options.sync="options"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="items"
          item-key="student_id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("uploaded Certificates") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <span title="Filter">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          :label="$t('Quarters')"
                          :items="quarters"
                          item-text="name"
                          item-value="id"
                          @change="resetData()"
                          v-model="selectedQuarter"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="selectGrade"
                          :label="$t('Grade')"
                          :items="grades"
                          @change="hideLabel = true && getClasses()"
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="selectedClasse"
                          :label="$t('Class')"
                          :items="classes"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.certificate="{ item }">
            <v-btn
              big
              v-if="item.certificate"
              class="main-color"
              @click="dowenload(item.certificate)"
              :title="$t('Download Certificate')"
              style="background-color: transparent; box-shadow: none !important"
            >
              <img src="../../../src/assets/icons/download.svg" />
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              big
              class="main-color"
              @click="OpenDialogImport(item)"
              :title="$t('Upload Certificate')"
              style="background-color: transparent; box-shadow: none !important"
            >
              <img src="../../../src/assets/icons/upload.svg" />
            </v-btn>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>

    <!-- import modale -->
    <v-dialog v-model="ImportDialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="3"
              ><span class="headline"> {{ $t("Import") }} </span></v-col
            >
            <v-col cols="12" md="7"
              ><p>{{ itemObject.name }}</p></v-col
            >
            <v-col cols="12" md="2">
              <!-- <v-icon class="main-color" @click="Close"
                >mdi-close</v-icon
              > -->
            </v-col>
          </v-row>

          <!-- <v-spacer></v-spacer> -->
        </v-card-title>

        <v-card-text>
          <v-container>
            <div v-if="uploadingError">
              <v-row class="buttons-space">
                <v-icon :color="colorIcon" class="iconSpace"
                  >attach_file</v-icon
                >
                <v-spacer></v-spacer>
                <v-icon :color="colorIcon" v-if="successMsg != ''">done</v-icon>
                <v-icon :color="colorIcon" v-if="errorIcon">error</v-icon>

                <v-progress-linear
                  rounded
                  :color="colorIcon"
                  :indeterminate="indeterminate"
                  height="10"
                  :value="valueProgress"
                  striped
                ></v-progress-linear>
              </v-row>

              <v-row
                style="
                  margin-top: 25px !important;
                  margin-bottom: 7px !important;
                "
              >
                <div>
                  <p
                    class="success--text text-center margin-auto"
                    role="alert"
                    style="margin-top: -15px !important"
                    v-if="successMsg != ''"
                  >
                    {{ successMsg }}
                  </p>
                  <br />
                </div>
                <div v-if="Object.keys(validation_errors).length !== 0">
                  <p v-for="(error, index) in validation_errors" :key="index">
                    <v-icon class="main-color">error</v-icon> {{ error }}
                  </p>
                </div>
              </v-row>
            </div>
            <div>
              <v-row class="buttons-space">
                <v-col md="12" sm="12">
                  <div class="custom-file" id="upload-photo">
                    <input
                      type="file"
                      accept=".pdf"
                      class="custom-file-input"
                      ref="fileObject"
                    />
                    <label class="custom-file-label" for="customFile">
                      <br
                    /></label>
                  </div>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col sm="6"></v-col>
                <v-col md="3" sm="3">
                  <v-btn class="modal-btn-default main-color" @click="Close"
                    >{{ $t("Cancel") }}
                  </v-btn>
                </v-col>

                <v-col md="3" sm="3">
                  <v-btn class="modal-btn-save" @click.prevent="uploadFile"
                    >{{ $t("Import") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <!-- <div v-if="importFile">
              <v-row class="buttons-space">
                <v-col md="6" sm="6">
                  <p>{{ itemObject.name }}</p>
                </v-col>
                <v-col md="6" sm="6">
                  <v-btn class="modal-btn-import" @click="ShowImportDiv"
                    >{{ $t("Import") }}
                    <v-spacer></v-spacer>
                    <img
                      title="Import File"
                      width="30px"
                      src="../../../src/assets/icons/upload-white.svg"
                      class="color"
                  /></v-btn>
                </v-col>
              </v-row>
            </div> -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import ACL from "../../acl";
export default {
  name: "uploadedCertificates",

  data() {
    return {
      options: {
        itemsPerPage: -1,
      },
      snack: "",

      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      templateUrlLink: "",
      ImportDialog: false,
      // importDiv: false,
      uploadingError: false,
      // importFile: true,
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Code"),
          value: "code",
          sortable: true,
        },
        {
          text: this.$i18n.t("Name"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$i18n.t("Certificate"),
          value: "certificate",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      // fileObject: "",
      title: this.$i18n.t("Uploaded Certificates"),
      itemObject: {},
      items: [],
      filter: {
        types: [],
      },
      selectGrade: "",
      selectedQuarter: "",
      selectedClasse: "",
      quarters: [],
      grades: [],
      classes: [],
      indeterminate: false,
      valueProgress: 0,
      error: "",
      successMsg: "",
      validation_errors: {},
      errorIcon: false,
      colorIcon: "blue",
    };
  },
  watch: {
    selectedClasse: {
      handler() {
        if (this.selectedClasse != "") {
          this.getAllData();
        }
      },
      deep: true,
    },
  },
  methods: {
    OpenDialogImport(item) {
      this.itemObject = item;
      this.ImportDialog = true;
    },
    Close() {
      this.reset();
      this.ImportDialog = false;
    },
    ShowImportDiv() {
      this.colorIcon = "blue";
    },
    reset() {
      this.error = "";
      this.successMsg = "";
      this.validation_errors = {};
      this.itemObject = {};
      this.errorIcon = false;

      this.uploadingError = false;
      this.colorIcon = "blue";
      // this.$refs.fileObject = [];
    },
    uploadFile() {
      let url = "/performance/students/upload-certificate";

      const fd = new FormData();

      if (this.$refs.fileObject.files.length > 0) {
        fd.append("file", this.$refs.fileObject.files[0]);
        fd.append("student_id", this.itemObject.student_id);
        fd.append("quarter_id", this.selectedQuarter);
        fd.append("class_id", this.selectedClasse);
        axios
          .post(this.getApiUrl + url, fd, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            // if validation error
            if (response.data) {
              if (
                response.data.status.error &&
                response.data.status.validation_errors.length != 0 &&
                Object.keys(response.data.status.validation_errors).length != 0
              ) {
                //console.log(response.data.status.validation_errors);
                this.errorIcon = true;
                this.validation_errors = response.data.status.validation_errors;
                this.colorIcon = "red";
                this.valueProgress = 20;
              } else if (
                (response.data.status.error &&
                  response.data.status.validation_errors.length == 0) ||
                (response.data.status.error &&
                  Object.keys(response.data.status.validation_errors).length ===
                    0 &&
                  response.data.status.validation_errors.constructor === Object)
              ) {
                //console.log(response.data.status.validation_errors);
                this.errorIcon = true;
                this.validation_errors = [response.data.status.message];
                this.colorIcon = "red";
                this.valueProgress = 20;
              } else {
                this.successMsg = response.data.status.message;
                this.errorIcon = false;
                this.colorIcon = "green";
                this.valueProgress = 100;
              }
              this.indeterminate = false;
              this.Close();
              this.getAllData();
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        // console.log("else no file");
        this.indeterminate = false;
        this.uploadingError = true;
        this.error = "Please select file first";
        this.colorIcon = "red";
        this.errorIcon = true;
        this.validation_errors = ["Please select file first"];
      }
    },
    getQuarters() {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quarters = response.data.data.quarters;

          //  this.filter.quarters =[];
          this.selectedQuarter = response.data.data.currntQuarterid;
        });
    },
    getGrades() {
      axios
        .get(this.getApiUrl + "/homework/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },
    getClasses() {
      axios
        .get(this.getApiUrl + "/homework/getClasses/" + this.selectGrade, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data.classes;
        });
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },

    getAllData() {
      if (this.selectedQuarter != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/students/" +
              this.selectedClasse +
              "/" +
              this.selectedQuarter,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.items = [];
            this.items = response.data.data;
            this.loading = false;
          });
      }
    },
    Back() {
      this.importDiv = false;
      this.importFile = true;
      this.uploadingError = false;
      this.colorIcon = "blue";
    },

    dowenload(cirtificate) {
      window.open(cirtificate, "_blank");
    },
    resetData() {
      this.items = [];
      this.getAllData();
    },
  },
  mounted() {
    this.getQuarters();
    this.getGrades();
    // this.getClasses();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
